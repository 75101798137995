export default [
	{
		name: 'Asian Americans Advancing Justice | AAJC',
		url: 'https://advancingjustice-aajc.org/',
		about:
			'Fights for Asian American civil rights through education, litigation, and public policy advocacy.',
		location: 'Washington D.C.',
	},
	{
		name: 'Asian American Pacific Islanders for Civic Empowerment',
		url: 'https://apacevotes.org/',
		about:
			'Increases access to and participation in electoral and civic affairs by registering, educating and protecting Asian American and Pacific Islander voters.',
		location: 'National',
	},
	{
		name: 'Asian American Psychological Association',
		url: 'https://aapaonline.org/',
		about:
			'The Asian American Phychological Association advances the mental health and well-being of Asian American communities through research, professional practice, education, and policy.',
		location: 'National',
	},
	{
		name: 'Center for Asian American Media',
		url: 'https://caamedia.org/',
		about:
			'A nonprofit organization dedicated to presenting stories that convey the richness and diversity of Asian American experiences to the broadest audience possible.',
		location: 'San Francisco, CA',
	},
	{
		name: 'Yuri Education Project',
		url: 'https://www.yurieducationproject.com/about',
		about:
			'YURI provides educational resources and experiences through an Asian American lens. By expanding the narratives of history, we believe knowledge can create bridges rather than division.',
		location: 'National',
	},
	{
		name: 'Immigrant History Initiative',
		url: 'https://www.immigranthistory.org/mission.html',
		about:
			'Provides comprehensive education resources that center on the histories and lived experiences of immigrant communities and people of color.',
		location: 'National',
	},
	{
		name: 'Anti-Racist APUSH',
		url: 'https://www.antiracistapush.com/',
		about:
			'Helps students identify and expose the racist policies that have led to the deplorable racial disparities in American society.',
		location: 'National',
	},
	{
		name: 'Asia Art Archive in America',
		url: 'http://www.aaa-a.org/',
		about:
			'An independent non-profit that documents the multiple recent histories of art in Asia, with a valuable collection about art available from its website and onsite',
		location: 'New York City, NY',
	},
	{
		name: 'Asian American Legal Defense and Education Fund',
		url: 'https://www.aaldef.org/about/',
		about:
			'A New York-based national organization founded in 1974, protects and promotes the civil rights of Asian Americans.',
		location: 'New York City, NY',
	},
	{
		name: 'Oakland Chinatown Coalition',
		url:
			'https://docs.google.com/forms/d/e/1FAIpQLSdt9wSYBvxhA1lfXpJv-2gLpFQ2S-S3dXmR5qz8TFRdOAeQdw/closedform',
		about:
			'Works to implement planning to make nine key improvements to our neighborhood through a strong community engagement process: public safety, access to jobs, affordable housing, community facilities/open space, small businesses, transportation, cultural preservation, and public health.',
		location: 'Oakland, CA',
	},
	{
		name: 'Womankind',
		url: 'https://www.iamwomankind.org/',
		about:
			'A New York based organization that works with survivors of gender-based trauma primarily in Asian communities',
		location: 'New York City, NY',
	},
	{
		name: 'National Asian American Pacific Womens Forum',
		url: 'https://www.napawf.org/',
		about:
			'Building collective power with AAPI women and girls to gain full agency over our lives, our families and our communities',
		location: 'National',
	},
	{
		name: 'Stop Discriminasian',
		url: 'https://www.stopdiscriminasian.org/',
		about:
			'A coalition of workers within arts and culture who are confronting the racism, xenophobia and violence towards diasporic Asians that have been amplified by the COVID-19 pandemic.',
		location: 'National',
	},
	{
		name: 'Stop AAPI Hate',
		url: 'https://stopaapihate.org/',
		about:
			'The Asian Pacific Planning and Policy Council (A3PCON), Chinese for Affirmative Action (CAA), and the Asian American Studies Department of San Francisco State University launched the Stop AAPI Hate reporting center on March 19.',
		location: 'National',
	},
	{
		name: 'FAR-NEAR Resources',
		url:
			'https://docs.google.com/document/d/1kPr67aBJ7IFAh97ifTEMxiQ8KpXOQE8J_sQ6gPFstIw/edit?usp=sharing',
		about:
			'A full list of resources and organizations curated by the FAR-NEAR community.',
		location: 'National',
	},
]
