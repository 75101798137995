import React, {useEffect, useState, useRef} from 'react';
import constants from '../constants'
export default function Loader({children, main}) {
    const [ready, setReady] = useState(false)
    const ref = useRef()

    useEffect(() => {
        ref.current = setTimeout(() => setReady(true), 3000)

        return () => clearTimeout(ref.current)
    }, [])
    return (
        <>
        {ready ? children : <div className={`loader-line absolute left-0 bg-black ${main ? 'loader-line-main':''}`}></div>}
        <style>{`
            .loader-line {
                top: 0px;
                width: 0%;
                height: 5px;
                animation: 2s loadAnimation 1s forwards;
            }

            .loader-line-main {
                top: ${constants.barWidth +4}px;
            }
        `}
        </style>
        </> 
    )
}