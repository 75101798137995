import React, { useRef, useState, useEffect } from 'react'
import Footer from '../components/Footer'
import PageHeader from '../components/PageHeader'
import Typewriter from '../components/Typewriter'

import ShopifyBuy from '@shopify/buy-button-js'

import shirt from '../img/tshirt.mp4'
//import shirtMobile from "../img/shirt.gif";

import constants from '../constants'

const client = ShopifyBuy.buildClient({
	domain: 'social-studies-worldwide.myshopify.com',
	storefrontAccessToken: 'd9897e2b6e9129c254eed9c90d16eeba',
})

const ui = ShopifyBuy.UI.init(client)

export default function Shop() {
	const [size, setSize] = useState(null)

	const btnSmall = useRef()
	const btnMed = useRef()
	const btnLarge = useRef()
	const btnXl = useRef()
	const btn2xl = useRef()

	/*useEffect(() => {
		ui.createComponent('product', {
			id: '6018173862085',
			node: btnSmall.current,
			options: {
				toggle: {
					styles: {
						toggle: {
							color: '#fff',
							'border-radius': '0px',
							'background-color': '#000',
							':hover': {
								'background-color': '#666',
							},
							':focus': {
								'background-color': '#666',
							},
						},
					},
				},
				cart: {
					iframe: false,
					sticky: false,
					popup: false,
				},
				product: {
					iframe: false,
					contents: {
						img: false,
						title: false,
						price: false,
					},
					text: {
						button: 'S',
					},
				},
			},
		})

		ui.createComponent('product', {
			id: '6018174779589',
			node: btnMed.current,
			options: {
				toggle: {
					styles: {
						toggle: {
							color: '#fff',
							'border-radius': '0px',
							'background-color': '#000',
							':hover': {
								'background-color': '#666',
							},
							':focus': {
								'background-color': '#666',
							},
						},
					},
				},
				cart: {
					iframe: false,
					sticky: false,
					popup: false,
				},
				product: {
					iframe: false,
					contents: {
						img: false,
						title: false,
						price: false,
					},
					text: {
						button: 'M',
					},
				},
			},
		})

		ui.createComponent('product', {
			id: '6018175860933',
			node: btnLarge.current,
			options: {
				toggle: {
					styles: {
						toggle: {
							color: '#fff',
							'border-radius': '0px',
							'background-color': '#000',
							':hover': {
								'background-color': '#666',
							},
							':focus': {
								'background-color': '#666',
							},
						},
					},
				},
				cart: {
					iframe: false,
					sticky: false,
					popup: false,
				},
				product: {
					iframe: false,
					contents: {
						img: false,
						title: false,
						price: false,
					},
					text: {
						button: 'L',
					},
				},
			},
		})

		ui.createComponent('product', {
			id: '6018177401029',
			node: btnXl.current,
			options: {
				toggle: {
					styles: {
						toggle: {
							color: '#fff',
							'border-radius': '0px',
							'background-color': '#000',
							':hover': {
								'background-color': '#666',
							},
							':focus': {
								'background-color': '#666',
							},
						},
					},
				},
				cart: {
					iframe: false,
					sticky: false,
					popup: false,
				},
				product: {
					iframe: false,
					contents: {
						img: false,
						title: false,
						price: false,
					},
					text: {
						button: 'XL',
					},
				},
			},
		})

		ui.createComponent('product', {
			id: '6018178842821',
			node: btn2xl.current,
			options: {
				toggle: {
					styles: {
						toggle: {
							color: '#fff',
							'border-radius': '0px',
							'background-color': '#000',
							':hover': {
								'background-color': '#666',
							},
							':focus': {
								'background-color': '#666',
							},
						},
					},
				},
				cart: {
					iframe: false,
					sticky: false,
					popup: false,
				},
				product: {
					iframe: false,
					contents: {
						img: false,
						title: false,
						price: false,
					},
					text: {
						button: '2XL',
					},
				},
			},
		})
	}, [])*/

	return (
		<div className='shop relative text-black h-screen overflow-hidden'>
			<PageHeader title='Shop' color='#000' bg='#F8EB76' />
			<div className='absolute top-0 left-0 w-full h-full z-50 flex justify-center items-center text-4xl uppercase'>
				Coming <span className='font-type'>Soon</span>
			</div>
			{/*
			<h1 className='font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>
					Social <span className='font-type'>Studies</span> crew tee,
					designed by{' '}
					<span className='font-type'>Cactus Plant Flea Market</span>.
					All proceeds will be donated to{' '}
					<a
						href='https://www.aclu.org/'
						target='_blank'
						rel='noopener noreferrer'
						className='underline hover:text-hover font-type'
					>
						ACLU
					</a>{' '}
					and{' '}
					<a
						href='https://www.freedommarchnyc.com/'
						target='_blank'
						rel='noopener noreferrer'
						className='underline hover:text-hover font-type'
					>
						Freedom March NYC.
					</a>
				</Typewriter>
			</h1>

			<video
				autoPlay
				muted
				playsInline
				loop
				className='shop__video block mx-auto pointer-events-none'
			>
				<source type='video/mp4' src={shirt} />
			</video>

			<div className='fixed md:absolute bottom-0 w-full'>
				<div className='relative flex w-full md:-mt-20 text-center pb-4'>
					<div className='flex-grow text-2xl md:text-4xl lg:text-5xl uppercase leading-none text-left pl-4'>
						Buy Now!
					</div>
					<div
						ref={btnSmall}
						className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none hover:text-hover font-type hover:underline hover:font-hover'
					></div>

					<div
						ref={btnMed}
						className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none hover:text-hover font-type hover:underline hover:font-hover'
					></div>

					<div
						ref={btnLarge}
						className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none hover:text-hover font-type hover:underline hover:font-hover'
					></div>

					<div
						ref={btnXl}
						className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none hover:text-hover font-type hover:underline hover:font-hover'
					></div>

					<div
						ref={btn2xl}
						className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none hover:text-hover font-type hover:underline hover:font-hover'
					></div>

					<div className='flex-grow text-2xl md:text-4xl lg:text-5xl leading-none text-right pr-4'>
						$60
					</div>
				</div>
				<Footer />
			</div>
      */}
			<style>{`
        .shop__video {
          max-width: 450px;
        }

        @media(max-width: ${constants.sm}) {
          .shop__video {
            max-width: 300px;
          }
        }

        @media(min-width: ${constants.xl}) {
          .shop__video {
            max-width: 600px;
          }
        }

        .overlay {

          backdrop-filter: blur(7px) saturate(150%);
        }
      `}</style>
		</div>
	)
}
