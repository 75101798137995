import React from 'react'
import Typewriter from './Typewriter'

export default function EventItem({border = true, eventData}) {
    return (
        <div className={`block md:flex ${border ? 'border-t' : ''} p-4`}>
            <div className="md:w-1/2">
                <div className="uppercase font-type">{eventData.date} <span className="pl-2">{eventData.time}</span></div>
                <div className="uppercase">{eventData.name}</div>
                <div className="font-type">{eventData.hosts}</div>
            </div>
            <div className="md:w-1/2">
                <span dangerouslySetInnerHTML={{__html: eventData.description}} />
            </div>
        </div>
    )
}