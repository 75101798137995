import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useWindowSize from '../utils/useWindowSize'

import Header from '../components/Header'
import Section from '../components/Section'
import MainScreen from '../components/MainScreen'

import constants from '../constants'

// pages
import Landing from './Landing'
import Conversations from './Conversations'
import About from './About'
import Shop from './Shop'
import Library from './Library'
import Gallery from './Gallery'
import Learn from './Learn'

export default function App() {
	const { width } = useWindowSize()
	const [sectionNum, setSectionNum] = useState(-1)
	let { pathname } = useLocation()

	useEffect(() => {
		if (pathname) {
			const p = pathname.replace(/\/$/, '')
			let s = -1
			switch (p) {
				case '':
				case '/':
				case '/home':
					s = -1
					break
				case '/conversations':
					s = 0
					break
				/*case '/gallery':
					s = 1
					break*/
				case '/library':
					s = 1
					break
				case '/learn':
					s = 2
					break
				case '/shop':
					s = 3
					break
				case '/about':
					s = -2
					break
				default:
					s = -1
			}

			setSectionNum(s)
		}
	}, [pathname])

	const getCurrentSection = () => {
		const p = pathname.replace(/\/$/, '')
		switch (p) {
			case '':
			case '/':
			case '/home':
				return <Landing />
			case '/conversations':
				return <Conversations />
			case '/gallery':
				return <Gallery />
			case '/library':
				return <Library />
			case '/learn':
				return <Learn />
			case '/shop':
				return <Shop />
			case '/about':
				return <About />
			default:
				return <Landing />
		}
	}

	return (
		<div className={`home absolute w-screen h-screen md:overflow-hidden`}>
			<Header />
			{pathname === '/' || pathname === '' ? (
				<Landing />
			) : width > constants.mobile ? (
				<div className='slides overflow-hidden w-screen'>
					{sectionNum === -2 ? (
						<MainScreen
							page={getCurrentSection()}
							currentSection={sectionNum}
						/>
					) : null}
					<Section
						key={1}
						hash='conversations'
						title='Conversations'
						index={0}
						currentSection={sectionNum}
						page={getCurrentSection()}
					/>
					{/*<Section key={2} hash="gallery" title="Gallery" index={1} currentSection={sectionNum} page={getCurrentSection()} />*/}
					<Section
						key={2}
						hash='library'
						title='Library'
						index={1}
						currentSection={sectionNum}
						page={getCurrentSection()}
					/>
					<Section
						key={3}
						hash='learn'
						title='Learn + Give'
						index={2}
						currentSection={sectionNum}
						page={getCurrentSection()}
					/>
					<Section
						key={4}
						hash='shop'
						title='Shop'
						index={3}
						currentSection={sectionNum}
						page={getCurrentSection()}
					/>
				</div>
			) : (
				<MainScreen
					page={getCurrentSection()}
					currentSection={sectionNum}
				/>
			)}

			<style>{`
                .home {
                    
                }

                .slides {
                    height: calc(100vh - ${constants.headerHeight}px);
                }

                .home__header {
                    height: ${constants.headerHeight}px;
                    border-bottom: 1px solid #000;
                }
            `}</style>
		</div>
	)
}
