import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Typewriter from '../components/Typewriter'
import Loader from '../components/Loader'

import constants from '../constants'

const images = [
	{
		image: '/landing/julia-chiang.jpg',
		credit: 'Julia Chiang',
	},
	{
		image: '/landing/virgil-abloh.jpg',
		credit: 'Virgil Abloh',
	},
	{
		image: '/landing/skuf-ykk.jpg',
		credit: 'SKUF YKK',
	},
	{
		image: '/landing/sam-friedman.jpg',
		credit: 'Sam Friedman',
	},
	{
		image: '/landing/cleofus.gif',
		credit: 'Cleofus',
	},
	{
		image: '/landing/verdy.jpg',
		credit: 'Verdy',
	},
]

export default function Landing() {
	const [loaded, setLoaded] = useState(false)
	const [welcome, setWelcome] = useState(true)
	const randomImage = useRef(
		images[Math.floor(Math.random() * images.length) + 1 - 1]
	)
	useEffect(() => {
		/*
		if (loaded) {
			setTimeout(() => {
				setWelcome(true)
			}, 3000)
		}*/
	}, [loaded])

	return welcome ? (
		<div className='home__overlay absolute w-full h-screen z-100 top-0 flex justify-center items-center'>
			<div className='container bg-white p-4 md:mx-0'>
				<h1 className='font-display text-3xl md:text-4xl lg:text-6xl leading-none'>
					<Typewriter delay={1000}>
						Welcome to SOCIAL{' '}
						<span className='font-type'>STUDIES</span> - a creative
						space for community education and empowerment.
						<Link to={'/conversations'}>
							<div className='pulse underline mt-10'>Enter</div>
						</Link>
					</Typewriter>
				</h1>
			</div>
		</div>
	) : (
		<>
			<div
				className='home__image absolute w-full h-screen  top-0 flex justify-center items-center'
				style={{ marginTop: constants.barWidth + 4 }}
			>
				<Loader />
				<div
					className='container bg-white md:mx-0 '
					style={{ marginTop: -constants.barWidth + 4 }}
				>
					<img
						src={randomImage.current.image}
						alt={randomImage.current.credit}
						className={`${
							loaded ? 'fadeIn' : 'opacity-0'
						} block mx-auto w-full lg:w-1/2 p-2`}
						onLoad={() => setLoaded(true)}
					/>
				</div>
			</div>
			<div className='home__image__credit absolute bottom-0 left-0 uppercase p-2'>
				Sticker by{' '}
				<span className='font-type'>{randomImage.current.credit}</span>
			</div>
		</>
	)
}
