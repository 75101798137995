import React, { useState } from 'react'
import Ticker from 'react-ticker'
import useWindowSize from '../utils/useWindowSize'
import PageVisibility from 'react-page-visibility'
import constants from '../constants'

export default function TickerTape() {
	const { width } = useWindowSize()
	const [pageIsVisible, setPageIsVisible] = useState(true)

	const handleVisibilityChange = (isVisible) => {
		setPageIsVisible(isVisible)
	}

	return (
		<>
			<PageVisibility onChange={handleVisibilityChange}>
				{width > constants.mobile && pageIsVisible ? (
					<Ticker height={constants.headerHeight}>
						{({ index }) => (
							<div
								className='flex font-type items-center'
								dangerouslySetInnerHTML={{
									__html:
										'&nbsp;TAKE ACTION!&nbsp;---&nbsp;&nbsp;',
								}}
							/>
						)}
					</Ticker>
				) : null}
			</PageVisibility>

			<style>{`
                .ticker__element {
                    height: 100%;
                    display: flex;
                    line-height: 1;
                    font-size: ${constants.menuFontSize};
                }
            `}</style>
		</>
	)
}
