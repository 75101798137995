import React from 'react'
import constants from '../constants'

export default function PageHeader({title = '', color = '#000', bg = '#fff'}) {
    return (
        <div className="md:hidden w-full z-10 fixed border-b flex justify-center items-center uppercase" style={{
            height: constants.barWidth,
            fontSize: constants.menuFontSize,
            color,
            backgroundColor: bg,
            top: constants.barWidth+4
        }}>{title}</div>
    )
}