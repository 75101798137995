import React from 'react'
import Footer from '../components/Footer'
import PageHeader from '../components/PageHeader'
import Typewriter from '../components/Typewriter'

import learnData from '../data/learn'
import learnDatav1 from '../data/learnDatav1'

export default function Learn() {
	return (
		<div className='learn relative text-black'>
			<PageHeader title='Learn' color='#000' bg='#E47B3A' />
			<h1 className='font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>ESSENTIAL ANTI-ASIAN RACISM RESOURCES</Typewriter>
			</h1>

			<div className='border-t p-4'>
				<div className='pl-0 py-4'>
					This list of resources is inspired by the{' '}
					<a
						href='https://far-near.media/'
						target='_blank'
						className='underline font-type hover:text-hover'
					>
						FAR–NEAR
					</a>{' '}
					community and is intended to serve as a resource to Asian
					people and allies to promote inclusion, safety and
					understanding. If you are here to deepen your anti-racism
					work, we welcome you. Feel free to circulate these resources
					on social media and with your friends, family, and
					colleagues.
					<br />
					<br />
					Subscribe{' '}
					<a
						href='https://mailchi.mp/06c2a02ed891/antiracismnewsletter'
						target='_blank'
						className='underline font-type hover:text-hover'
					>
						here
					</a>{' '}
					to join FAR-NEAR's monthly anti-racism newsletter
				</div>
			</div>

			<div className='hidden md:flex border-t uppercase'>
				<div className='md:w-1/4 font-type px-4'>Name</div>
				<div className='md:w-1/2 font-type px-4'>About</div>
				<div className='md:w-1/4 font-type px-4'>Location</div>
			</div>

			{learnData.map((learn, l) => {
				return (
					<div key={l} className='block md:flex border-t py-4'>
						<div className='md:w-1/4 font-type px-4 uppercase'>
							<a
								href={learn.url}
								target='_blank'
								rel='noopener noreferrer'
								className='underline hover:text-hover'
							>
								{learn.name}
							</a>
						</div>
						<div className='py-4 md:py-0 md:w-1/2 px-4'>
							{learn.about}
						</div>
						<div className='md:w-1/4 font-type px-4 uppercase'>
							{learn.location}
						</div>
					</div>
				)
			})}

			<h1 className='border-t font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>
					A preview of additional topics important to the SOCIAL{' '}
					<span className='font-type'>STUDIES</span> community,
					written by <span className='font-type'>Mariel Cruz</span>.
					Continue reading, learning, and discussing.
				</Typewriter>
			</h1>

			<div className='border-t p-4'>
				<div className='font-type text-2xl md:text-4xl lg:text-5xl leading-none uppercase'>
					Voter Supression
				</div>
				<div className='pl-0 pt-4 md:pl-20'>
					Voter suppression in the U.S. dates back to the founding of
					the Electoral College in the early 1800s, when enslaved
					people were not only denied the right to vote, but were
					deemed to count as three-fifths of a human being in order to
					grant additional voting power to their owners. It sharpened
					its focus in the Jim Crow era, when felony
					disenfranchisement laws were passed in many states,
					effectively banning Americans with felony (and in some
					cases, even misdemeanor) convictions from voting. For life.
					That practice continues today, along with other tactics:
					making felons pay outstanding fees and/or fines in order to
					vote, closing polling places in majority Black or Latinx
					neighborhoods, illegal and unreported erasure of voter
					registrations, and gerrymandering, or revising state
					district lines specifically to capture a majority of votes
					for a political party, as opposed to simply reflecting a
					state’s political makeup accurately, as it exists. Voting is
					how we hold leaders accountable—it’s a powerful tool that
					must be equally accessible to every citizen.
				</div>
			</div>

			<div className='border-t p-4'>
				<div className='font-type text-2xl md:text-4xl lg:text-5xl leading-none uppercase'>
					Systemic Racism
				</div>
				<div className='pl-0 pt-4 md:pl-20'>
					There are the personal assumptions pervasive in racist
					thinking—that black men are natural athletes, for example,
					or that if you’re first-generation you’re not American (“No,
					but where are you ​really​ from?”)—and then there’s systemic
					racism. Simply stated, it denotes a system of practices that
					treats people differently based on race. In the U.S.,
					everything from bank lending practices to arrests for
					marijuana possession, from infant mortality rates to
					unemployment reflects a lack of equal value for Black and
					brown people. Critical work in this area focuses on
					dismantling these systems, reversing its practices, and
					implementing new structures firmly rooted in fairness and
					equality.
				</div>
			</div>

			<h1 className='font-display uppercase border-t text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>
					A collection of social justice organizations to volunteer
					with and donate to, curated by{' '}
					<a
						href='https://www.instagram.com/awakenewyorkclothing/'
						target='_blank'
						rel='noopener noreferrer'
						className='underline font-type hover:text-hover'
					>
						Awake NY.
					</a>
				</Typewriter>
			</h1>

			<div className='hidden md:flex border-t uppercase'>
				<div className='md:w-1/4 font-type px-4'>Name</div>
				<div className='md:w-1/2 font-type px-4'>About</div>
				<div className='md:w-1/4 font-type px-4'>Location</div>
			</div>

			{learnDatav1.map((learn, l) => {
				return (
					<div key={l} className={`block md:flex border-t py-4`}>
						<div className='md:w-1/4 font-type px-4 uppercase'>
							<a
								href={learn.url}
								target='_blank'
								rel='noopener noreferrer'
								className='underline hover:text-hover'
							>
								{learn.name}
							</a>
						</div>
						<div className='py-4 md:py-0 md:w-1/2 px-4'>
							{learn.about}
						</div>
						<div className='md:w-1/4 font-type px-4 uppercase'>
							{learn.location}
						</div>
					</div>
				)
			})}

			<Footer />
		</div>
	)
}
