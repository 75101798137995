export default [
	{
		image: '/library/MinorFeelings_CathyParkHong.jpg',
		title: 'Minor Feelings',
		author: 'Cathy Park Hong',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/605371/minor-feelings-by-cathy-park-hong/',
		libraryLink:
			'https://www.worldcat.org/title/minor-feelings-an-asian-american-reckoning/oclc/1227029481&referer=brief_results',
	},
	{
		image:
			'/library/Oxfords_AVeryShortIntroductiontoAsianAmericanHistory.jpg',
		title: "Oxford's A Very Short Introduction to Asian American History",
		author: 'Madeline Y. Hsu',
		buyNowLink:
			'https://global.oup.com/academic/product/asian-american-history-a-very-short-introduction-9780190219765?cc=us&lang=en&',
	},
	{
		image: '/library/Orientalism.jpg',
		title: 'Orientalism',
		author: 'Edward Said',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/159783/orientalism-by-edward-w-said/',
		libraryLink:
			'https://www.worldcat.org/title/orientalism/oclc/1200830761&referer=brief_results',
	},
	{
		image: '/library/Shanzhai_DeconstructioninChinese.jpg',
		title: 'Shanzhai: Deconstruction in Chinese',
		author: 'Byung Chul Han',
		buyNowLink: 'https://mitpress.mit.edu/books/shanzhai',
		libraryLink:
			'https://www.worldcat.org/title/shanzhai-deconstruction-in-chinese/oclc/1016027636&referer=brief_results',
	},
	{
		image: '/library/StoriesoftheSahara.jpg',
		title: 'Stories of the Sahara',
		author: 'Sanmao',
		buyNowLink:
			'https://www.bloomsbury.com/au/stories-of-the-sahara-9781408881880/',
		libraryLink:
			'https://www.worldcat.org/title/stories-of-the-sahara/oclc/1124599759&referer=brief_results',
	},
	{
		image: '/library/ExitWest.jpg',
		title: 'Exit West',
		author: 'Mohsin Hamid',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/549017/exit-west-by-mohsin-hamid/',
		libraryLink:
			'https://www.worldcat.org/title/exit-west/oclc/1057237285&referer=brief_results',
	},
	{
		image: '/library/WomanNativeOther.jpg',
		title: 'Woman, Native, Other',
		author: 'Trinh T Minh-Ha',
		buyNowLink: 'https://iupress.org/9780253205032/woman-native-other/',
		libraryLink:
			'https://www.worldcat.org/title/woman-native-other/oclc/438702461&referer=brief_results',
	},
	{
		image: '/library/OnEarthWereBrieflyGorgeous.jpg',
		title: "On Earth We're Briefly Gorgeous",
		author: 'Ocean Vuong',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/600633/on-earth-were-briefly-gorgeous-by-ocean-vuong/',
		libraryLink:
			'https://www.worldcat.org/title/on-earth-were-briefly-gorgeous/oclc/1196079197&referer=brief_results',
	},
	{
		image: '/library/IBecomeaNisei.jpg',
		title: 'I Become a Nisei',
		author: 'Isamu Noguchi',
		buyNowLink:
			'https://shop.noguchi.org/products/i-become-a-nisei-second-printing',
		libraryLink:
			'https://www.worldcat.org/title/i-become-a-nisei/oclc/1244811123&referer=brief_results',
	},
	{
		image: '/library/Discontent_and_its_Civilizations.jpg',
		title: 'Discontent and its Civilizations',
		author: 'Mohsin Hamid',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/317527/discontent-and-its-civilizations-by-mohsin-hamid/',
		libraryLink:
			'https://www.worldcat.org/title/discontent-and-its-civilizations/oclc/898464217&referer=brief_results',
	},
	{
		image: '/library/ABurning.jpg',
		title: 'A Burning',
		author: 'Megha Majumdar',
		buyNowLink:
			'https://www.penguinrandomhouse.com/books/624372/a-burning-by-megha-majumdar/',
		libraryLink:
			'https://www.worldcat.org/title/burning/oclc/1227386706&referer=brief_results',
	},
	{
		image: '/library/RedFlowerTheWomenofOkinawa.jpg',
		title: 'Red Flower: The Women of Okinawa',
		author: 'Mao Ishikawa',
		buyNowLink:
			'https://www.shashasha.co/en/book/red-flower-the-women-of-okinawa',
		libraryLink:
			'https://www.worldcat.org/title/red-flower-the-women-of-okinawa/oclc/1106046092&referer=brief_results',
	},
	{
		image: '/library/TheNextAmericanRevolution.jpg',
		title: 'Living for Change or The Next American Revolution',
		author: 'Grace Lee Boggs',
		buyNowLink:
			'https://www.ucpress.edu/book/9780520272590/the-next-american-revolution',
	},
	{
		image: '/library/QueeringContemporaryAsianAmericanArt.jpg',
		title: 'Queering Contemporary Asian American Art',
		author: 'Laura Kina and Jan Christian Bernabe',
		buyNowLink:
			'https://uwapress.uw.edu/book/9780295741376/queering-contemporary-asian-american-art/',
		libraryLink:
			'https://www.worldcat.org/title/queering-contemporary-asian-american-art/oclc/1023064881&referer=brief_results',
	},
	{
		image: '/library/Severance.jpg',
		title: 'Severance',
		author: 'Ling Ma',
		buyNowLink: 'https://us.macmillan.com/books/9780374261597',
		libraryLink:
			'https://www.worldcat.org/title/severance/oclc/1173994314&referer=brief_results',
	},
	{
		image: '/library/FarNear.jpg',
		title: 'FAR–NEAR Volume 1 - 3',
		author: 'Far Near',
		buyNowLink: 'https://far-near.media/shop/far-near-volume-bundle/',
	},
	{
		image: '/library/TheRacialTriangulationofAsianAmericans.png',
		title: 'The Racial Triangulation of Asian Americans',
		author: 'Claire Jean Kim',
		libraryLink:
			'https://drive.google.com/file/d/1n_499S2X25GbEcLN2wf461Zo-rW2VPmd/view?usp=sharing',
	},
	{
		image: '/library/skin.jpg',
		title: 'Yellow Skin, White Gold',
		author: 'Anne Anlin Cheng',
		libraryLink:
			'https://aaa.org.hk/en/ideas/ideas/yellow-skin-white-gold/type/essays',
	},
]
