import React from 'react'
import { useLocation } from 'react-router-dom'
import constants from '../constants'
import Loader from '../components/Loader'
import useWindowSize from '../utils/useWindowSize'

export default function MainScreen({page, currentSection}) {
    const { width } = useWindowSize()
    let { pathname } = useLocation()
    return (
        <Loader key={currentSection} main={currentSection === -2 || width < constants.mobile}>
            <div id="scroll2" className={`main-screen absolute ${pathname !== '/shop' ? 'md:overflow-auto' : ''} `}>
                {/*currentSection === -2  ? page : null*/}
                {page}

                <style>{`
                    .main-screen {
                        width: 100%;
                        margin-top: ${constants.barWidth*2}px;
                    }

                    @media (min-width: ${constants.md}) {
                        .main-screen {
                            width: calc(100vw - ${constants.barWidth * (constants.sections)}px);
                            height: calc(100vh - ${constants.barWidth}px);
                            position: relative;
                            margin-top: 0px;
                            min-height: inherit;
                        }
                    }
                `}</style>
            </div>
        </Loader>
    )
}