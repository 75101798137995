import React, {useState, useEffect} from 'react'
import TypeWriter from 'react-typewriter'
import { useInView } from 'react-intersection-observer';

export default function Typewriter({children, delay = 500}) {
    const {ref, inView} = useInView();
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        // only play once
        if(inView) {
            setVisible(true)
        }
    }, [inView])
    return (
        <div ref={ref}>
            <TypeWriter initDelay={delay} typing={visible ? 1 : 0} minDelay={1} maxDelay={10} fixed>
                {children}
            </TypeWriter>
        </div>
    )
}