import React from 'react'
import Footer from '../components/Footer'
import PageHeader from '../components/PageHeader'
import Typewriter from '../components/Typewriter'

import Image from '../components/Image'
import galleryData from '../data/gallery'

import constants from '../constants'

export default function Gallery() {
    return (
        <div className="gallery relative text-black">
            <PageHeader title="Gallery" color="#000" bg="#E83F3F" />
            <h1 className="font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4">
                <Typewriter>An exhibition curated by <a href="https://www.instagram.com/mediumtings" target="_blank" rel="noopener noreferrer" className="font-type underline hover:text-hover">Medium Tings.</a> and <a href="https://www.instagram.com/innamost/" target="_blank" rel="noopener noreferrer" className="font-type underline hover:text-hover">Jonathan Gardenhire.</a></Typewriter>
            </h1>

            {galleryData.map((artist, a) => {
                return (
                    <div key={a}>
                        <div className="border-t p-4">{artist.name.toUpperCase()} <span className="font-type">{artist.bio}</span></div>
                        {artist.art.map((art, aindex) => {
                            return (
                                <div key={aindex} className={`border-t`}>
                                    <Image src={art.image} alt={art.title} className={`gallery__image p-4 ${aindex % 2 === 0 ? '' : 'ml-auto'}`} />
                                    <div className="md:flex justify-between p-4 border-t uppercase">
                                        <div className="">{artist.name}</div>
                                        <div className="gallery__mw md:text-right">{art.title}<br /><span className="font-type">{art.info}</span></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )
            })}

            <h1 className="font-display border-t uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4">
                For more information and pricing, please <a href="mailto:hello@mediumtingsbk.com" target="_blank" rel="noopener noreferrer" className="font-type underline hover:text-hover">contact MEDIUM TINGS.</a>
            </h1>

            <Footer />

            <style>{`

                .gallery__image {
                    max-height: 80vh;
                }

                @media(max-width: ${constants.sm}) {
                    .gallery__image {
                        margin: 0 auto;
                    }
                }

                @media(min-width: ${constants.md}) {
                    .gallery__mw {
                        max-width: 60%;
                    }
                }

                
                
            `}</style>
        </div>
    )
}