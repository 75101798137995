import React from 'react'
import Typewriter from '../components/Typewriter'
import Footer from '../components/Footer'
import Image from '../components/Image'
import PageHeader from '../components/PageHeader'

import about1 from '../img/about1.jpg'
import about2 from '../img/about2.jpg'
import about3 from '../img/about3.jpg'

export default function About() {
    return(
        <div className="about relative text-black">
            <PageHeader title="About" color="#fff" bg="#000" />
            <div className="block md:flex">
                <div className="md:w-2/3">
                    <div className="p-4">
                        <p>Social <span className="font-type">Studies</span> is a multi-day experience that brings artists and brands together for community-driven programming, with the primary goal of empowering and educating underserved youth to exercise and access their creativity.</p>
                        <p>Established by <a href="https://www.instagram.com/angelobaque/" target="_blank" rel="noopener noreferrer" className="underline hover:text-hover">Baque Creative</a>, <a href="https://instagram.com/sheekswinsalways" target="_blank" rel="noopener noreferrer" className="underline hover:text-hover">Shaniqwa Jarvis</a> and <a href="https://instagram.com/somethingspecialstudios" target="_blank" rel="noopener noreferrer" className="underline hover:text-hover">Something Special Studios</a>, Social Studies was launched during Miami Art Basel 2017. The sophomore edition took place in NYC at Milk Studios in 2018.</p>
                        <p>Each iteration of Social Studies deftly combines curatorial workshops, educational panels/conversations, exclusive drops, and more into a singular, robust activation that seeks to propel our youth forward creatively, financially, emotionally, and ethically.</p>
                        <p>By partnering with an eclectic mix of both emerging and established community figures, Social Studies gives its audience the opportunity to acquire an unprecedented and diverse expanse of knowledge and experience, while also personally connecting with the brands and artists they love.</p>
                    </div>
                </div>
                <div className="md:w-1/3">
                    <div className="p-4 font-type">
                        <a href="mailto:l@awakenyclothing.com" className="underline hover:text-hover">Press Inquiries</a><br />
                        <a href="mailto:thankyou@somethingspecialstudios.com" className="underline hover:text-hover">General Inquiries</a>    
                    </div>
                </div>
            </div>

            <div className="md:flex">
                <div className="md:w-1/3"><Image src={about1} alt="Previous Social Studies" className="p-4" /></div>
                <div className="md:w-1/3"><Image src={about2} alt="Previous Social Studies" className="p-4" /></div>
                <div className="md:w-1/3"><Image src={about3} alt="Previous Social Studies" className="p-4" /></div>
            </div>


            <div className="leading-none border-t border-b p-2 uppercase text-center">
                Social <span className="font-type">Studies Partners</span>
            </div>
            
            <div className="p-4">
                <a href="https://www.instagram.com/angelobaque/" target="_blank" rel="noopener noreferrer" className="block text-center underline font-type text-xl hover:text-hover">Baque Creative</a>
                <p>Established in 2017, Baque Creative is a multi-directional agency created in order to provide a platform for artists and creators who may not otherwise have means of entry to certain cultural spaces. Baque Creative's goal is to support and visualize the diverse, vital global networks that the agency and its founder Angelo Baque play an active part in. With philosophies of access and education at its heart, Baque Creative devotes it’s efforts to working on thoughtful, innovative and inclusive projects that put communities first and help bridge the gaps between high and low cultures.</p>
                <p>In addition to Baque Creative, Angelo Baque heads the streetwear brand Awake NY, which is inspired by his native home of New York City and the intermingling of cultures on which it thrives. Baque Creative Press, which was founded alongside the agency is a aimed at publishing thoughtful, limited-run edition monographs featuring the work of visual artists, frequently Baque’s close colleagues and longtime collaborators.</p>
            </div>

            <div className="p-4 border-t-1">
                <a href="https://instagram.com/sheekswinsalways" target="_blank" rel="noopener noreferrer" className="block text-center underline font-type text-xl hover:text-hover">Shaniqwa Jarvis</a>
                <p>Shaniqwa Jarvis is an artist known for combining a modern fashion aesthetic with sensitive and emotional portraiture. She captures vivid reality across a wide variety of subjects that always appear to be an extension of herself in images that speak to raw, disparate feelings imbued with a deep sparkling optimism. Jarvis has executed large scale portrait projects and exhibits in London, Tokyo, Los Angeles and New York. She co-created Social Studies, a experiential retail pop up focusing on connecting the youth to creators through curated programing. She has released a range of creative products with companies like Adidas, The Standard Hotels Group, SNS and Supreme. Jarvis has released her first book, a collection of work in a new, limited run, self-titled debut. Shaniqwa Jarvis, hardbound at 160 pages delves into Jarvis’ archive of photography and combines editorial and personal work spanning two decades.</p>
            </div>

            <div className="p-4 border-t-1">
                <a href="https://instagram.com/somethingspecialstudios" target="_blank" rel="noopener noreferrer" className="block text-center underline font-type text-xl hover:text-hover">Something Special Studios</a>
                <p>Something Special Studios is a creative company in New York City. We offer a range of services — from idea to execution — partnering with a global family of artists and experts to find unexpected answers to common questions. Built on a studio model of collaboration, we work closely with our clients and community to create things that reflect the shared vision of everyone at the table. The result, we hope, is work that pushes us all to think a bit differently.</p>
                <p>Select clients include Nike, Calvin Klein, Budweiser, Dior, Slam Jam, Moncler Genius, Uniqlo x Alexander Wang, Depop, Beats, Brooklyn Museum, Levi’s, and Hypebeast.</p>
                <p>ssspecial projects is our platform for studio-born initiatives. At times this means creative pursuits originating within the walls of SSS. Other times this utilizing our network and services to bring the ideas creative partners to life. ssspecial projects enables exploration and experimentation within the studio, as well as the opportunity for creative exchange with artists, non- profits, cultural institutions, and the like. Through ssspecial projects, SSS has published books, produced multiple art exhibitions and album release parties, led fundraising campaigns, and more.</p>
            </div>

            <Footer />
        </div>
    )
}