export default [
	{
		image: '/library/TylerMitchellICanMakeYouFeelGood_720x2.jpg',
		title: 'I Can Make You Feel Good',
		author: 'Tyler Mitchell',
		buyNowLink:
			'https://rep.club/collections/books/products/i-can-make-you-feel-good-tyler-mitchell',
		libraryLink:
			'https://www.worldcat.org/title/i-can-make-you-feel-good/oclc/1126577193',
	},
	{
		image: '/library/blackimagination_720x1.jpg',
		title: 'Black Imagination: Black Voices on Black Futures',
		author: 'Natasha Marin',
		buyNowLink:
			'https://rep.club/products/black-imagination-by-natasha-marin?pr_prod_strat=copurchase&amp;pr_rec_pid=5608632287399&amp;pr_ref_pid=5397079785639&amp;pr_seq=uniform',
		libraryLink: 'http://www.worldcat.org/oclc/1150165225',
	},
	{
		image: '/library/niakin352ZZZ__original_1024x1024.jpg',
		title: 'Queer and Trans Artists of Color',
		author: 'Nia King',
		buyNowLink:
			'https://otherbooksla.com/collections/art-books/products/queer-and-trans-artists-of-color-by-nia-king',
		libraryLink: 'http://www.worldcat.org/oclc/891147387',
	},
	{
		image: '/library/41VWLpDZpjL1.jpg',
		title: 'Queer and Trans Artists of Color (Volume 2)',
		author: 'Nia King',
		buyNowLink:
			'https://otherbooksla.com/collections/art-books/products/queer-trans-artists-of-color-volume-2-by-nia-king',
		libraryLink: 'http://www.worldcat.org/oclc/965830537',
	},
	{
		image: '/library/GucciMANE_720x1.jpg',
		title: 'The Gucci Mane Guide To Greatness',
		author: 'Gucci Mane with Soren Baker',
		buyNowLink:
			'https://rep.club/collections/books/products/gucci-mane-guide',
		libraryLink: 'http://www.worldcat.org/oclc/1162351236',
	},
	{
		image:
			'/library/jack-whitten-notes-from-the-woodshed-1_1024x1024@2x1.jpg',
		title: 'Jack Witten: Notes From The Woodshed',
		author: 'Jack Whitten',
		buyNowLink:
			'https://otherbooksla.com/collections/art-books/products/jack-whitten-notes-from-the-woodshed',
		libraryLink: 'http://www.worldcat.org/oclc/1041227878',
	},
	{
		image: '/library/9783791354347_1024x1024.jpg',
		title: 'Noah Purifoy: Junk Dada',
		author: null,
		buyNowLink:
			'https://otherbooksla.com/collections/art-books/products/noah-purifoy-junk-dada',
		libraryLink:
			'https://www.worldcat.org/title/noah-purifoy-junk-dada/oclc/1086255566&referer=brief_results',
	},
	{
		image:
			'/library/collid_books_covers_0_isbn_9780262036603_type_1024x1024.jpg',
		title:
			'Trap Door: Trans Cultural Production and the Politics of Visiblity',
		author: null,
		buyNowLink:
			'https://otherbooksla.com/collections/art-books/products/trap-door-trans-cultural-production-and-the-politics-of-visibility',
		libraryLink: 'http://www.worldcat.org/oclc/1029877583',
	},
	{
		image: '/library/columbus.jpg',
		title: 'They Came Before Columbus',
		author: 'Ivan Van Sertima',
		buyNowLink:
			'https://www.newbeaconbooks.com/african-american-non-fiction/they-came-before-columbus-journal-of-african-civilizations',
		libraryLink: 'http://www.worldcat.org/oclc/845617380',
	},
	{
		image: '/library/shaniqwajarvisbook_720x1.jpg',
		title: 'Shaniqwa Jarvis',
		author: 'Shaniqwa Jarvis',
		buyNowLink:
			'https://rep.club/products/shaniqwa-jarvis-book?_pos=1&amp;_sid=41fb94be2&amp;_ss=r',
		libraryLink: 'http://www.worldcat.org/oclc/1031404099',
	},
	{
		image: '/library/Screen_Shot_2019-10-23_at_5.451.jpg',
		title: 'The New Black Vanguard: Photography Between Art & Fashion',
		author: 'Antwaun Sargent',
		buyNowLink:
			'https://rep.club/products/the-new-black-vanguard-photography-between-art-and-fashion-by-antwaun-sargent?_pos=2&amp;_sid=41fb94be2&amp;_ss=r',
		libraryLink: 'http://www.worldcat.org/oclc/1141122154',
	},
	{
		image: '/library/41da0b29d7c607a607ac105bbbd1c8521.jpg',
		title: 'Deana Lawson: An Aperture Monograph',
		author: 'Deana Lawson',
		buyNowLink:
			'https://rep.club/products/deana-lawson-aperture?_pos=1&_sid=628d6cb58&_ss=r',
		libraryLink: 'http://www.worldcat.org/oclc/1066018444',
	},
	{
		image: '/library/fortheporch001_tpgbookshop_580x1.jpg',
		title: 'For the Porch',
		author: 'Cam Hicks',
		buyNowLink:
			'https://paradigmpublishing.co/products/for-the-porch-cam-hicks',
		libraryLink: null,
	},
	{
		image: '/library/BlackFuturesKimberlyDrewJennaWortham_900x1.jpg',
		title: 'Black Futures',
		author: 'Kimberly Drew & Jenna Wortham',
		buyNowLink:
			'https://rep.club/products/black-futures-kimberly-drew-jenna-wortham?_pos=2&amp;_sid=5ee8ba7f1&amp;_ss=r',
		libraryLink: 'http://www.worldcat.org/oclc/1090279852',
	},
	{
		image: '/library/97817847876911.jpg',
		title: 'If They Come in the Morning...: Voices of Resistance',
		author: 'Angela Y. Davis',
		buyNowLink:
			'https://bookshop.org/books/if-they-come-in-the-morning-voices-of-resistance/9781784787691?aid=3762&amp;listref=what-we-re-reading-right-now',
		libraryLink: 'http://www.worldcat.org/oclc/1028386746',
	},
]
