import React, {useState, useEffect} from 'react'
import { useInView } from 'react-intersection-observer';

export default function Image({src, alt = '', className}) {
    const {ref, inView} = useInView()
    const [viewed, setInView] = useState(false)

    useEffect(() => {
        if(!viewed && inView) {
            setInView(true)
        }
    }, [inView])
    
    return (
        <img ref={ref} src={src} alt={alt} className={`img_fade ${className} ${viewed ? 'opacity-1' : 'opacity-0'}`} />
    )
}