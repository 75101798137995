export default [
	{
		date: '10.29.2020',
		time: '1:00PM EST',
		name: 'Black Power Lunch Hour',
		hosts: 'Kimberly Drew w/ Haben Girma',
		description: `Writer and curator Kimberly Drew gets to know disability rights activist and the first Deafblind graduate of Harvard Law School, Haben Girma, for her ongoing series, Black Power Lunch Hour.<br /><br /><a href="/transcript1.html" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
		image: '/conversations/kimberly-haben.jpg',
		video: '473282402',
	},
	{
		date: '10.29.2020',
		time: '2:00PM EST',
		name: 'The Black & Brown Vote',
		hosts: "De'Ara Balenger w/ Paola Ramos",
		description: `Co-founder of Maestra, attorney, and Hillary Clinton alum De’Ara Balenger sits down with journalist, Vice host, author of the newly released <span class="italic">Finding Latinx</span>, and Hillary and Obama alum, Paola Ramos, to talk about voting and the upcoming election.<br /><br /><a href="/deara.html" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
		image: '/conversations/d-p.jpg',
		video: '473283108',
	},
	{
		date: '10.29.2020',
		time: '3:30PM EST',
		name: 'How I Made It',
		hosts: 'Chris Gibbs w/ Reggieknow',
		description: `Chris Gibbs, owner of Union Los Angeles, interviews the artist Reggieknow about his journey coming up in the creative industry.<br /><br /><a href="/chris-reggie.html" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
		image: '/conversations/reggie-chris.jpg',
		video: '473284067',
	},
	{
		date: '10.29.2020',
		time: '5:30PM EST',
		name: 'Rebel Music',
		hosts: 'Tremaine Emory and Acyde',
		description: `Tremaine Emory and Acyde of No Vacancy Inn talk songs of rebellion. This event will take place on Instagram Live <a href="https://instagram.com/socialstudies.worldwide" target="_blank" class="hover:text-hover underline font-type">@socialstudies.worldwide.</a>`,
	},
	{
		date: '10.30.2020',
		time: '1:00PM EST',
		name: 'Black Power Lunch Hour',
		hosts: 'Kimberly Drew w/ Maurice Harris',
		description: `Writer and curator Kimberly Drew talks to floral artist and founder of Bloom & Plume, Maurice Harris, for her ongoing series, Black Power Lunch Hour.<br /><br /><a href="/transcript2.html" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
		video: '473977710',
		image: '/conversations/kim.jpg',
	},
	{
		date: '10.30.2020',
		time: '2:00PM EST',
		name: 'Sincerity In Design',
		hosts: 'Brendan Fowler',
		description:
			'Artist Brendan Fowler explores sincerity, intention, and activism in art and design. This event will take place on Instagram Live <a href="https://instagram.com/socialstudies.worldwide" class="underline hover:text-hover font-type" target="_blank">@socialstudies.worldwide</a>.',
	},
	{
		date: '10.30.2020',
		time: '3:00PM EST',
		name: 'How I Made It',
		hosts:
			'Angelo Baque and Shaniqwa Jarvis w/ Jerry Lorenzo, Melody Ehsani, Paulo Calle, and Lena Waithe',
		description: `Angelo Baque and Shaniqwa Jarvis speak with some of their favorite creatives about their rise to the top.<br /><br /><a href="/angelo.html" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
		video: '473973894',
		image: '/conversations/angelo.jpg',
	},
]
