import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Ticker from '../components/Ticker'

import constants from '../constants'

import hamburger from '../img/hamburger.svg'

export default function Header() {
	const { pathname } = useLocation()

	const [menu, setMenu] = useState(false)

	const toggleMenu = () => {
		if (menu) {
			setMenu(false)
		} else {
			setMenu(true)
		}
	}

	useEffect(() => {
		setMenu(false)
	}, [pathname])

	return (
		<div className='home__header border-bottom flex w-full fixed md:relative bg-white z-50'>
			<div className='home__header__logo uppercase flex items-center justify-center'>
				<Link
					to='/conversations'
					className='flex items-center justify-center w-full h-full relative text-black block hover:text-white hover:bg-black'
				>
					Social <span className='font-type'>Studies</span>
				</Link>
			</div>
			<div className='home__header__ticker flex-auto bg-gray'>
				<Ticker />
			</div>
			<div className={`home__header__about uppercase font-type`}>
				<Link
					to='/about'
					className={`flex items-center justify-center w-full h-full relative hover:text-white hover:bg-black ${
						pathname === '/about'
							? 'bg-black text-white'
							: 'bg-white text-black'
					}`}
				>
					About
				</Link>
			</div>

			<div className='home__header__hamburger absolute right-0 top-0 h-full flex items-center justify-center'>
				<button onClick={toggleMenu}>
					<img src={hamburger} alt='Menu' className='w-3/4' />
				</button>
			</div>

			<div
				className={`home__header__dropdown bg-white w-full absolute z-50 ${
					menu ? '' : 'hidden'
				}`}
			>
				<ul>
					<li
						className={`${
							pathname === '/conversations' ||
							pathname === '/home'
								? 'hidden'
								: ''
						} text-center border-b border-black bg-white uppercase`}
					>
						<Link to='/conversations' className='block p-2 '>
							Conversations
						</Link>
					</li>
					{/*<li className={`${pathname === '/gallery' ? 'hidden' : ''} text-center border-b border-black bg-white uppercase`}><Link to="/gallery" className="block p-2">Gallery</Link></li>*/}
					<li
						className={`${
							pathname === '/library' ? 'hidden' : ''
						} text-center border-b border-black bg-white uppercase`}
					>
						<Link to='/library' className='block p-2'>
							Library
						</Link>
					</li>
					<li
						className={`${
							pathname === '/learn' ? 'hidden' : ''
						} text-center border-b border-black bg-white uppercase`}
					>
						<Link to='/learn' className='block p-2'>
							Learn + Give
						</Link>
					</li>
					<li
						className={`${
							pathname === '/shop' ? 'hidden' : ''
						} text-center border-b border-black bg-white uppercase`}
					>
						<Link to='/shop' className='block p-2'>
							Shop
						</Link>
					</li>
					<li
						className={`${
							pathname === '/about' ? 'hidden' : ''
						} text-center border-b border-black bg-white uppercase`}
					>
						<Link to='/about' className='block p-2'>
							About
						</Link>
					</li>
				</ul>
			</div>

			<style>{`
                .home__header {
                    
                }

                .home__header__logo {
                    width: ${constants.barWidth * 5}px;
                    font-size: ${constants.menuFontSize};
                }

                .home__header__ticker {
                    border-left: 1px solid #000;
                }

                .home__header__dropdown a {
                    font-size: ${constants.menuFontSize};
                }

                .home__header__about {
                    border-left: 1px solid #000;
                    width: ${constants.barWidth * constants.sections}px;
                    font-size: ${constants.menuFontSize};
                }

                .home__header__hamburger {
                    display: none;
                    width: 50px;
                }

                .home__header__dropdown {
                    top: ${constants.headerHeight}px;
                    transition: 1000ms max-height ease-out;
                    /*max-height: 0px;*/
                }

                .home__header__dropdown__open {
                    /*max-height: 220px;*/
                }

                @media (max-width: ${constants.mobile}px) {
                    .home__header__hamburger {
                        display: flex;
                    }

                    .home__header__logo {
                        width: 100%;
                    }

                    .home__header__ticker,
                    .home__header__about {
                        display: none;
                    }
                }
            `}</style>
		</div>
	)
}
