export default [
	{
		name: 'ACLU',
		url: 'https://aclu.org',
		about:
			"The American Civil Liberties Union was founded in 1920 and is our nation's guardian of liberty. The ACLU works in the courts, legislatures and communities to defend and preserve the individual rights and liberties guaranteed to all people in this country by the Constitution and laws of the United States.",
		location: 'National',
	},
	{
		name: 'Black Lives Matter LA',
		url: 'https://www.blmla.org/',
		about:
			'Black Lives Matter emerged from the hearts and minds of its three co-founders: Patrisse Cullors, Alicia Garza and Opal Tometi. It came to life in Los Angeles, where the first chapter was birthed as a reclamation of and recommitment to Black radical organizing and Black freedom struggle.',
		location: 'Los Angeles,CA',
	},
	{
		name: 'Black Visions Collective',
		url: 'https://www.blackvisionsmn.org/',
		about:
			'Since 2017, Black Visions Collective has been putting into practice the lessons learned from organizations before them in order to shape a political home for Black people across Minnesota. They aim to center their work in healing and transformative justice principles, intentionally develop the organization’s core “DNA” to ensure sustainability, and develop Minnesota’s emerging Black leadership to lead powerful campaigns. ',
		location: 'Minnesota',
	},
	{
		name: 'Building Black Bed Stuy',
		url: 'https://www.instagram.com/buildingblackbedstuy/?hl=en',
		about:
			'An organization formed to protect and preserve the Black community in Bed Stuy and beyond by providing financial relief for Black-owned businesses and organizations.',
		location: 'New York, NY',
	},
	{
		name: 'Color of Change',
		url: 'https://colorofchange.org/',
		about:
			'Color Of Change is the nation’s largest online racial justice organization. They help people respond effectively to injustice in the world around us. As a national online force driven by 7 million members, they move decision-makers in corporations and government to create a more human and less hostile world for Black people in America.',
		location: 'National',
	},
	{
		name: 'Equality for Flatbush',
		url: 'http://www.equalityforflatbush.org/',
		about:
			'An organization leading anti-displacement campaigns in Brooklyn to combat the borough’s rapid gentrification.',
		location: 'New York, NY',
	},
	{
		name: 'NAACP Legal Defense and Educational Fund',
		url: 'https://www.naacpldf.org/',
		about:
			'The NAACP Legal Defense and Educational Fund is America’s premier legal organization fighting for racial justice. Through litigation, advocacy, and public education, LDF seeks structural changes to expand democracy, eliminate disparities, and achieve racial justice in a society that fulfills the promise of equality for all Americans. LDF also defends the gains and protections won over the past 80 years of civil rights struggle and works to improve the quality and diversity of judicial and executive appointments.',
		location: 'National',
	},
	{
		name: 'New Immigrant Community Empowerment (NICE)',
		url: 'https://www.nynice.org/',
		about:
			'A nonprofit organization dedicated to improving the lives of vulnerable and precarious immigrant workers in New York, with a focus on day laborers, domestic workers, and newly arrived immigrants. They offer an extensive set of services, community organizing, and leadership development programs.',
		location: 'New York, NY',
	},
	{
		name: 'Reclaim the Block',
		url: 'https://www.reclaimtheblock.org/',
		about:
			'Reclaim the Block began in 2018 and organizes Minneapolis community and city council members to move money from the police department into other areas of the city’s budget that truly promote community health and safety. They believe health, safety and resiliency exist without police of any kind. They organize around policies that strengthen community-led safety initiatives and reduce reliance on police departments. They do not believe that increased regulation of or public engagement with the police will lead to safer communities, as community testimony and documented police conduct suggest otherwise.',
		location: 'Minneapolis, MN',
	},
	{
		name: 'Sky High Farm',
		url: 'https://www.skyhighfarm.org/',
		about:
			'Sky High Farm is a non-profit organization committed to addressing food security and nutrition by improving access to fresh, nutritious food for New Yorkers who are living in underserved communities by sustainably growing fresh fruits and vegetables, and raising livestock exclusively for the purposes of donation.',
		location: 'New York, NY',
	},
	{
		name: 'The Point Community Development Corporation',
		url: 'https://thepoint.org/',
		about:
			'THE POINT Community Development Corporation is a non-profit organization dedicated to youth development and the cultural and economic revitalization of the Hunts Point section of the South Bronx.',
		location: 'New York, NY',
	},
	{
		name: 'Until Freedom',
		url: 'https://untilfreedom.com',
		about:
			'Until Freedom is an intersectional social justice organization rooted in the leadership of diverse people of color to address systemic and racial injustice. They focus on investing in those who are the most directly impacted by cyclical poverty, inequality, and state violence. They are a clearinghouse for advocates, new & budding activists, seasoned community organizers, students, movement lawyers, entertainers and artists, policy experts, formerly & currently incarcerated individuals, and survivors of gun violence to work linearly to uplift all of our people. Everyone is worthy, everyone is needed to win. ',
		location: 'National',
	},
]
