import React, {useEffect, useState} from 'react'
import { useSpring, animated as a } from 'react-spring'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

import constants from '../constants'

export default function Section({currentSection, hash = 'section1', title = 'section', index = 0, page = () => null }) {
    const [left, set] = useState(false)
    const [active, setActive] = useState(false)
    const { transform } = useSpring({
        transform: `translateX(${left ? '0px' : window.innerWidth - (constants.sections * constants.barWidth) + 'px'})`,
        config: { mass: 5, tension: 500, friction: 80 }
    })

    useEffect(() => {
        if(currentSection >= index) {
            // stay on left wall
            set(true)
        } else {
            // stay on right wall
            set(false)
        }

        if(currentSection === index) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [currentSection]);

    return (

        <a.div className={`section-${index} section absolute`} style={{left: constants.barWidth * index + 'px', transform}}>
            <Link to={'/'+hash}>
                <div className={`section__bar bg-white absolute h-full flex justify-center items-center hover:bg-section${index} ${active ? 'bg-section'+index : null}`}>
                    <div className="font-type uppercase transform -rotate-90 whitespace-no-wrap text-black">{title}</div>
                </div>
            </Link>

            <div id="scroll" className={`section__content bg-white h-full w-full absolute ${index !== 4 ? 'overflow-auto' : ''}`}>
                {active ? <Loader>{page}</Loader> : null}
            </div>

            <style>{`
                .section {
                    height: calc(100vh - ${constants.headerHeight}px);
                    top: ${constants.headerHeight}px;
                }

                .section__bar {
                    width: ${constants.barWidth}px;
                    border-left: 1px solid #000;
                    transition: 300ms background-color;
                    font-size: ${constants.menuFontSize};
                }

                .section__content {
                    border-left: 1px solid #000;
                    width: calc(100vw - ${constants.barWidth * (constants.sections)}px);
                    left: ${constants.barWidth}px;
                }
            `}</style>
        </a.div>
    )
}