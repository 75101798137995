import React from 'react'
import constants from '../constants'
import logo from '../img/sss-logo.png'

export default function Footer() {
	return (
		<>
			<div className='footer block md:flex bg-gray border-t px-4 py-2'>
				<div className='text-base font-type uppercase md:w-1/2 text-center md:text-left'>
					<a
						href='https://instagram.com/socialstudies.worldwide'
						target='_blank'
						rel='noopener noreferrer'
						className='hover:text-hover'
					>
						@SocialStudies.Worldwide
					</a>
				</div>
				<div className='text-xs md:text-base fontfont-display uppercase md:w-1/2 text-center md:text-right'>
					<a
						href='https://somethingspecialstudios.com'
						target='_blank'
						rel='noopener noreferrer'
						className='flex justify-center md:justify-end hover:text-hover '
					>
						{/*<span className='block font-type leading-none mt-1 mr-2'>
							Website Design:
						</span> */}
						<img
							src={logo}
							className='sss-logo block'
							alt='Something Special Studios*'
						/>
					</a>
				</div>
			</div>
			<style>{`

            .sss-logo {
                width: 190px;
                height: 19px;
            }

            @media(max-width: ${constants.sm}) {
                .footer {
                    
                }
            }
        `}</style>
		</>
	)
}
