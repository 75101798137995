import React, { useState, useEffect } from 'react'
import Typewriter from '../components/Typewriter'
import EventItem from '../components/EventItem'
import Footer from '../components/Footer'
import PageHeader from '../components/PageHeader'
import Events from '../data/events'

import play from '../img/play.svg'
//import tomorrow from '../img/tomorrow.jpg'

export default function Home() {
	const [archive, setArchive] = useState(false)

	useEffect(() => {
		const scroll = document.getElementById('scroll')
		if (scroll) {
			scroll.scrollTo(0, 0)
		}

		const scroll2 = document.getElementById('scroll2')
		if (scroll2) {
			scroll2.scrollTo(0, 0)
		}
	}, [archive])

	return (
		<>
			<div className='home relative text-black'>
				<PageHeader title='Conversations' color='#000' bg='#4FAF8A' />
				{archive ? (
					<div>
						<div className='home__back p-4'>
							<button
								onClick={() => setArchive(false)}
								className='hover:text-hover'
							>
								BACK
							</button>
						</div>
						<div className='embed-responsive aspect-ratio-16/9'>
							<iframe
								title='stream'
								className='embed-responsive-item bg-black'
								src={`https://player.vimeo.com/video/${archive.video}?autoplay=1&loop=1&autopause=0`}
								frameBorder='0'
								allow='autoplay; fullscreen'
								allowFullScreen
							></iframe>
						</div>
						<div>
							<EventItem eventData={archive} />
						</div>
					</div>
				) : (
					<>
						<div className='embed-responsive aspect-ratio-16/9'>
							<iframe
								title='stream'
								className='embed-responsive-item bg-black'
								src='https://player.vimeo.com/video/537062949?autoplay=1&loop=1&autopause=0'
								frameBorder='0'
								allow='autoplay; fullscreen'
								allowFullScreen
							></iframe>
							{/*<img src={tomorrow} alt="Tomorrow" className="embed-responsive-item bg-black" />*/}
						</div>
						<div>
							<EventItem
								eventData={{
									date: '04.16.2021',
									time: '',
									name: 'Self-Realized: Asian In America',
									hosts:
										'"Minya "Miss Info" Oh w/ Julie Won, Zara Rahim, Maia Ruth Lee, Kyle Ng, and Jeff Staple.',
									description: `Maia Ruth Lee, Zara Rahim, Julie Won, Jeff Staple, and Kyle Ng talk with moderator Minya Oh about being AAPI in the face of hate and erasure, with an eye on the future.<br /><br /><a href="/210415_SocialStudies_Conversations_Transcript.pdf" target="_blank" class="font-type underline hover:text-hover">Transcript</a>`,
									image: '',
									video: '537062949',
								}}
							/>
						</div>
						{/*<div className="text-2xl md:text-3xl lg:text-5xl leading-none border-t p-4">
                            <Typewriter>SCHEDULE</Typewriter>
                        </div>
                        <div>
                            {Events.map((eventData, e) => {
                                return <EventItem key={e} eventData={eventData} />
                            })}
                        </div>*/}

						<div className='text-2xl md:text-3xl lg:text-5xl leading-none border-t p-4'>
							<Typewriter>PAST EVENTS</Typewriter>
						</div>

						<div className='md:grid md:grid-cols-2 border-t auto-rows-auto'>
							{Events.map((eventData, e) => {
								if (!eventData.video) return null
								return (
									<div
										key={e}
										className={`text-left ${
											e === 4 || e === 1 ? '' : ''
										}`}
									>
										<button
											onClick={() =>
												setArchive(eventData)
											}
										>
											<div
												className='relative bg-black aspect-ratio-16/9'
												style={{
													backgroundSize: 'cover',
													backgroundPosition:
														'center center',
													backgroundImage: `url(${eventData.image})`,
												}}
											>
												<img
													src={play}
													alt='Play'
													className='absolute'
													style={{
														width: '50px',
														top: '50%',
														left: '50%',
														transform:
															'translate(-50%, -50%)',
													}}
												/>
											</div>
											<div
												className={`p-4 ${
													e % 2 === 0 ? '' : ''
												}`}
											>
												<div className='text-left uppercase'>
													{eventData.name}
												</div>
												<div className='text-left font-type'>
													{eventData.hosts}
												</div>
												<div
													className='text-left'
													dangerouslySetInnerHTML={{
														__html:
															eventData.description,
													}}
												/>
											</div>
										</button>
									</div>
								)
							})}
						</div>
					</>
				)}

				<Footer />
			</div>

			<style>{`
                .home__overlay {
                    background: rgba(255,255,255,0.75);
                }
            `}</style>
		</>
	)
}
