import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom"
import App from "./pages/App"
import Soon from './pages/Soon'
//import Loading from "./components/Loader"


function ScrollToTop() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function R() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/about">
          <App />
        </Route>
        <Route path="/:section">
          <App />
        </Route>
        <Route exact path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  );
}
