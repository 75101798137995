export default [
    {
        name: 'Siena Smith',
        bio: "(b.1996, New York City) is an artist currently based in Chicago, IL. She works with drawing, weaving and collage to work out the complexities of identity and investigate personal narratives.",
        art: [
            {
                image: '/gallery/siena1.jpg',
                title: 'By The Pool',
                info: 'Jacquard Woven, 54”x72”, 2018'
            },
            {
                image: '/gallery/SienaSmith2.jpg',
                title: 'Float',
                info: 'Jacquard Woven, Heat transferable vinyl, 54”x72”, 2018'
            },
            { 
                image: '/gallery/SienaSmith3.jpg',
                title: 'LEANING',
                info: 'Jacquard Woven (stretched), 22”x40”, 2019'
            },
            {
                image: '/gallery/SienaSmith4.jpg',
                title: 'UP',
                info: 'Jacquard Woven (stretched), 48”x72”, 2018'
            }
        ]
    },
    {
        name: 'Turiya Adkins',
        bio: "is a multi-disciplinary artist born in NYC and currently residing in Hanover, NH. She is a recent 2020, honors graduate in Studio Art from Dartmouth College and received the Wolfenden Fine Arts Prize for her work in painting, sculpture, and printmaking. Turiya is now on the faculty at Dartmouth as a Teaching Assistant.",
        art: [
            {
                image: '/gallery/TuriyaAdkins1.png',
                title: 'I Remember You Was Conflicted',
                info: 'Oil paint, oil pastel and photo transfer on canvas, 16”x20”, 2020'
            },
            {
                image: '/gallery/TuriyaAdkins2.png',
                title: "It’s Getting Taken Regardless",
                info: 'Oil paint, acrylic paint, and photo transfer on canvas, 20”x30”, 2020'
            },
            {
                image: '/gallery/TuriyaAdkins3.png',
                title: "Alas, Poor Richard",
                info: 'Collage on found tile, 12”x20”, 2020'
            },
            {
                image: '/gallery/TuriyaAdkins4.png',
                title: "The Northern Protestant ",
                info: 'Wood and mixed media on copper, 11”X17”, 2020'
            },
            {
                image: '/gallery/TuriyaAdkins5.png',
                title: "UNTITLED",
                info: 'Collage, oil paint, OIL pastel, AND marker ON wood, 10X10”, 2020'
            }
        ]
    },
    {
        name: 'Edward Cushenberry',
        bio: "was born and raised in Orange County, California, which was cool for a moment. Now he lives in the Los Angeles/Pasadena area where he splits his time between photographing his life and drawing everything else that's going on in his world, in an attempt to merge real life with romanticism.",
        art: [
            {
                image: '/gallery/EdwardCushenberry1.png',
                title: 'I wait for no one',
                info: 'Prismacolor, Ink, Acrylic, Graphite on Bristol Paper, 3.5"x4.25”, 2020'
            },
            {
                image: '/gallery/EdwardCushenberry2.png',
                title: "Metro train salon",
                info: 'Prismacolor, Ink, Acrylic, Graphite on Bristol Paper, 3.5"x4.25”, 2020'
            },
            {
                image: '/gallery/EdwardCushenberry3.png',
                title: "She wants to be young and stupid again",
                info: 'Prismacolor, Ink, Acrylic, Graphite on Bristol Paper, 3.5"x4.25”, 2020'
            },
            {
                image: '/gallery/EdwardCushenberry4.png',
                title: "Love is Real",
                info: 'Prismacolor, Ink, Acrylic, Graphite on Bristol Paper, 3.5"x4.25”, 2020'
            }
        ]
    }
    ,
    {
        name: 'Sydney Vernon',
        bio: "(b.1995, Prince George’s County, MD) lives and works in New York, NY. Her work was previously exhibited at Thierry Goldberg Gallery, NY; Peg Alston Fine Arts, New York, NY; Prince George’s County African American Museum and Cultural Center, Brentwood, MD; and The White Room, Washington, DC.",
        art: [
            {
                image: '/gallery/SydneyVernon1.png',
                title: 'UNTITLED ',
                info: 'Ink, Paper, Charcoal, Oil, and Acetone Transfer on Paper, 79"x53", 2020'
            },
            {
                image: '/gallery/SydneyVernon2.png',
                title: "Triple Consciousness",
                info: 'Oil Paint, Pastel, and Silkscreen on Paper, 74"x51”, 2020'
            },
            {
                image: '/gallery/SydneyVernon3.png',
                title: "untitled",
                info: 'Gouache, Pastel, and Silkscreen on Paper, 49"x70”, 2020'
            }
        ]
    }
]