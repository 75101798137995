import React from 'react'
import Footer from '../components/Footer'
import PageHeader from '../components/PageHeader'
import Typewriter from '../components/Typewriter'
import Image from '../components/Image'
import libraryData from '../data/library.js'
import libraryDataV1 from '../data/library_v1'

export default function Library() {
	return (
		<div className='library relative text-black'>
			<PageHeader title='Library' color='#000' bg='#88C0E7' />
			<h1 className='font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>
					The following is a list of books and readings curated by{' '}
					<a
						href='https://far-near.media/'
						target='_blank'
						className='underline hover:text-hover font-type'
					>
						FAR-NEAR
					</a>{' '}
					that covers Asian history in America and Asian Americans'
					place in the racial discussion.
				</Typewriter>
			</h1>

			<div className='border-t md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
				{libraryData.map((book, b) => {
					return (
						<div
							key={b}
							className='mb-4 md:m-0 border px-2 py-4 text-center'
						>
							<Image
								src={book.image}
								alt={book.title}
								className='h-64 mx-auto'
							/>
							<div className='mt-4 text-xl uppercase'>
								{book.title}
							</div>
							{book.author ? <div>{book.author}</div> : null}
							<div className='text-xl font-type'>
								{book.buyNowLink ? (
									<>
										<a
											href={book.buyNowLink}
											target='_blank'
											rel='noopener noreferrer'
											className='underline hover:text-hover'
										>
											BUY NOW
										</a>
									</>
								) : null}
								{book.buyNowLink && book.libraryLink
									? ' | '
									: null}
								{book.libraryLink ? (
									<>
										<a
											href={book.libraryLink}
											target='_blank'
											rel='noopener noreferrer'
											className='underline hover:text-hover'
										>
											LIBRARY
										</a>
									</>
								) : null}
							</div>
						</div>
					)
				})}
			</div>

			<h1 className='font-display uppercase text-2xl md:text-4xl lg:text-5xl leading-none p-4'>
				<Typewriter>
					A selection of books celebrating Black voices, curated by{' '}
					<a
						href='https://www.instagram.com/_ajgirard'
						target='_blank'
						className='underline hover:text-hover font-type'
					>
						Antoine J. Girard.
					</a>
				</Typewriter>
			</h1>

			<div className='border-t md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
				{libraryDataV1.map((book, b) => {
					return (
						<div
							key={b}
							className='mb-4 md:m-0 border px-2 py-4 text-center'
						>
							<Image
								src={book.image}
								alt={book.title}
								className='h-64 mx-auto'
							/>
							<div className='mt-4 text-xl uppercase'>
								{book.title}
							</div>
							{book.author ? <div>{book.author}</div> : null}
							<div className='text-xl font-type'>
								{book.buyNowLink ? (
									<>
										<a
											href={book.buyNowLink}
											target='_blank'
											rel='noopener noreferrer'
											className='underline hover:text-hover'
										>
											BUY NOW
										</a>
									</>
								) : null}
								{book.buyNowLink && book.libraryLink
									? ' | '
									: null}
								{book.libraryLink ? (
									<>
										<a
											href={book.libraryLink}
											target='_blank'
											rel='noopener noreferrer'
											className='underline hover:text-hover'
										>
											LIBRARY
										</a>
									</>
								) : null}
							</div>
						</div>
					)
				})}
			</div>

			<Footer />
		</div>
	)
}
