export default {
	sections: 4,
	barWidth: 36,
	headerHeight: 40,
	menuFontSize: '20px',
	primary: '#ffffff',
	secondary: '#f33838',
	yellow: 'yellow',
	cyan: 'cyan',
	mobile: '768',
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
}
